import ISpot from "./ISpot";

import Icon from "../../../../images/markers/marker_r1-repidbus-stops.svg";

const r1_rapid_bus_route: ISpot = {
  color: "#2a3b7f",
  icon: Icon,
  category: "R1 RapidBus Route",
  map: {
    center: {
      lat: 49.162618328874615, lng: -122.84565343030756
    },
    zoom: 13,
  },
  markers: [
    {
      title: "Guildford Exchange",
      position: {
        lat: 49.19162282291084, lng: -122.80402521175742
      },
    },
    {
      title: "104 Ave @ 148 St",
      position: {
        lat: 49.19158369962416, lng: -122.81306913297466
      },
    },
    {
      title: "104 Ave @ 144 St",
      position: {
        lat: 49.1916226176658, lng: -122.82442698382306
      },
    },
    {
      title: "104 Ave @ 140 St",
      position: {
        lat: 49.19156478146014, lng: -122.83460867184657
      },
    },
    {
      title: "104 Ave @ Whalley Blvd",
      position: {
        lat: 49.18661509736235, lng: -122.84901894050147
      },
    },
    {
      title: "Surrey Central Station",
      position: {
        lat: 49.18940360809167, lng: -122.84776249172747
      },
    },
    {
      title: "King George Station",
      position: {
        lat: 49.183314409428746, lng: -122.84576602318764
      },
    },
    {
      title: "King George Blvd @ 96 Ave",
      position: {
        lat: 49.17601586634581, lng: -122.8460183038182
      },
    },
    {
      title: "King George Blvd @ 88 Ave",
      position: {
        lat: 49.161768809006645, lng: -122.8459969616706
      },
    },
    {
      title: "King George Blvd @ 80 Ave",
      position: {
        lat: 49.14724020302987, lng: -122.84590623856649
      },
    },
    {
      title: "King George Blvd @ 76 Ave",
      position: {
        lat: 49.140080720124274, lng: -122.84582837478307
      },
    },
    {
      title: "Newton Exchange",
      position: {
        lat: 49.13342261565423, lng: -122.84243075157744
      },
    },
  ],
};

export default r1_rapid_bus_route;
