import ISpot from "./ISpot";

import Icon from "../../../../images/markers/marker_restaurants-cafe.svg";

const restaurantsCafés: ISpot = {
  color: "#fbc02d",
  icon: Icon,
  category: "Restaurants & Cafés",
  map: {
    center: {
      lat: 49.162618328874615, lng: -122.84565343030756
    },
    zoom: 13,
  },
  markers: [
    {
      title: "Dominion Bar & Kitchen",
      position: {
        lat: 49.190235713624624, lng: -122.848226528545
      },
    },
    {
      title: "Browns Socialhouse",
      position: {
        lat: 49.178947928598944, lng: -122.84493629531913
      },
    },
    {
      title: "White Spot",
      position: {
        lat: 49.18758531154262, lng: -122.84594093336595
      },
    },
    {
      title: "Ruex Cafe and Bar",
      position: {
        lat: 49.18078449418536, lng: -122.84482692551094
      },
    },
    {
      title: "Fresgo Inn",
      position: {
        lat: 49.186355399485514, lng: -122.84444596442295
      },
    },
    {
      title: "Waffle House Diner",
      position: {
        lat: 49.181167467350186, lng: -122.84380622522377
      },
    },
    {
      title: "Starbucks",
      position: {
        lat: 49.137017007087486, lng: -122.84434447929488
      },
    },
    {
      title: "Spice of Nepal",
      position: {
        lat: 49.13322733973767, lng: -122.84855683346575
      },
    },
    {
      title: "Brewsters Sports Pub",
      position: {
        lat: 49.13691502353291, lng: -122.84509589460482
      },
    },
    {
      title: "Espresso Café",
      position: {
        lat: 49.13578492573158, lng: -122.84251062636227
      },
    },
    {
      title: "Minute Bakery",
      position: {
        lat: 49.14905366151804, lng: -122.84443874170543
      },
    },
  ],
};

export default restaurantsCafés;
