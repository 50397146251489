import React, { useEffect } from "react";
import { Loader } from "@googlemaps/js-api-loader";
import { useSelector } from "react-redux";
import _ from "lodash";
import S from "./style.module.sass";
import markerStar from "../../../images/marker-spera.svg";
import data from "./data";
import { getAmenities } from "../../../redux/presentationSlice";
import ISpot from "./data/ISpot";
import styles from './map-style.json';


const GoogleMapApp = () => {
  // eslint-disable-next-line no-undef
  const [googleMap, updateGoogleMap] = React.useState<google.maps.Map | null>(
    null
  );
  const [allMarkers, updateAllMarkers] = React.useState<Array<any>>([]);
  const ref = React.useRef();
  const amenities = useSelector(getAmenities);

  useEffect(() => {
    const loader = new Loader({
      apiKey: "AIzaSyBpZGxQ7UFHKwv9_9WKffPZujS38FwcZ44",
      version: "weekly",
      libraries: ["places"]
    });

    // Promise
    loader
      .load()
      .then((google) => {
        if (ref.current !== undefined) {
          const center = { lat: 49.15338592408428, lng: -122.84626901534337 };
          const zoom = 12;
          // const mapId = "bcec9569c288f7ac";
          // const mapId = "810ca5d221f660d6";
          const maxZoom = 16;
          const minZoom = 11;
          const zoomControl = true;
          const mapTypeControl = false;
          const streetViewControl = false;

          const mapOptions = {
            // mapId,
            center,
            zoom,
            maxZoom,
            minZoom,
            zoomControl,
            mapTypeControl,
            streetViewControl,styles
          };
          const map = new google.maps.Map(ref.current, mapOptions);

          const propertyMarker = new google.maps.Marker({
            position: { lat: 49.15333326757281, lng: -122.84622610286416 },
            icon: markerStar,
            zIndex: google.maps.Marker.MAX_ZINDEX + 1
          });
          propertyMarker.setMap(map);

          // @ts-ignore
          updateGoogleMap(map);

          const all: React.SetStateAction<any[]> = [];
          _.forEach(data, (spot) => {
            let i = 1;
            _.forEach(spot.markers, (marker) => {
              const markers = new google.maps.Marker({
                title: marker.title,
                position: marker.position,
                icon: {
                  url: `data:image/svg+xml;charset=UTF-8; base64, ${window.btoa(
                    `<svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="25px" height="25px" viewBox="0 0 25 25" ><circle fill-opacity="0.95" cx="12.5" cy="12.5" r="11.5" stroke="#ffffff11" fill="${spot.color}" /><text x="1.75"  y="-6" dominant-baseline="middle" text-anchor="middle" transform="matrix(1 0 0 1 10.8965 19.6958)" fill="white" style="font-size:12px; font-family: proxima-nova, sans-serif;">${i}</text></svg>`
                  )}`,
                  scale: 1,
                  // @ts-ignore
                  anchor: new google.maps.Point(0, 20)
                }
              });
              // @ts-ignore
              markers.category = spot.category;
              all.push(markers);
              markers.setMap(map);
              i += 1;
            });
          });
          updateAllMarkers(all);
        }
      })
      .catch((e) => {
        // do something
      });
  }, [ref]);

  useEffect(() => {
    _.forEach(allMarkers, (marker) => {
      marker.setVisible(marker.category === amenities);
    });

    const d  = _.find(data, {category: amenities}) as ISpot;


    if(d !== undefined) {
      googleMap?.setCenter(d.map.center)
      googleMap?.setZoom(d.map.zoom)
    }
  }, [amenities]);

  // @ts-ignore
  return <div ref={ref} className={S.map} />;
};

export default GoogleMapApp;
