import ISpot from "./ISpot";

import Icon from "../../../../images/markers/marker_education.svg";

const education: ISpot = {
  color: "#8f4417",
  icon: Icon,
  category: "Education",
  map: {
    center: {
      lat: 49.166429700817766, lng: -122.8461389547618
    },
    zoom: 13,
  },
  markers: [
    {
      title: "Simon Fraser University",
      position: {
        lat: 49.186739350967166,
        lng: -122.84907145051089
      },
    },
    {
      title: "Future UBC Campus",
      position: {
        lat: 49.18000836392657,
        lng: -122.84469183158062
      },
    },
    {
      title: "Stenberg College",
      position: {
        lat: 49.18784557212642,
        lng: -122.84962621348313
      },
    },
    {
      title: "Vancouver Career College",
      position: {
        lat: 49.19939071338414,
        lng: -122.8501501304858
      },
    },

    {
      title: "Sprott Shaw College",
      position: {
        lat: 49.18362493949438,
        lng: -122.8439123403727
      },
    },

    {
      title: "Kwantlen Polytechnic University",
      position: {
        lat: 49.13292957069619,
        lng: -122.8708269797598
      },
    },

    {
      title: "Lead Community College",
      position: {
        lat: 49.14729651441522,
        lng: -122.86557281701887
      },
    },

    {
      title: "Princess Margaret Secondary School",
      position: {
        lat: 49.1319487257497,
        lng: -122.86576505347409
      },
    },

    {
      title: "Newton Elementary",
      position: {
        lat: 49.15048075255889,
        lng: -122.85252392856603
      },
    },

    {
      title: "Guru Angad Dev Elementary School",
      position: {
        lat: 49.14313078746815,
        lng: -122.84856437805782
      },
    },

    {
      title: "Sikh Academy",
      position: {
        lat: 49.1576160454174,
        lng: -122.86472852841469
      },
    },

  ],
};

export default education;
