import ISpot from './ISpot';
import education from "./education";
import healthcare from "./healthcare";
import recreationParks from "./recreation-parks";
import shopping from "./shopping";
import cityServices from "./city-services";
import restaurantsCafés from "./restaurants-cafe";
import r1_rapid_bus_route from "./r1-rapidbus-route";
import skyTrainExpoLine from "./skytrain-expo-line";
import futureSurreyLangleySkyTrainLine from "./future-surrey-langley-skytrain-line";

const amenities: Array<ISpot> = [
    education,
    healthcare,
    recreationParks,
    shopping,
    cityServices,
    restaurantsCafés,
    r1_rapid_bus_route,
    skyTrainExpoLine,
    futureSurreyLangleySkyTrainLine
];

export default amenities;
