import ISpot from "./ISpot";

import Icon from "../../../../images/markers/marker_skytrain-expo-line.svg";

const skytrain_expo_line: ISpot = {
  color: "#7e2c65",
  icon: Icon,
  category: "SkyTrain Expo Line",
  map: {
    center: {
      lat: 49.180587370275646, lng: -122.84589229594673
    },
    zoom: 13,
  },
  markers: [
    {
      title: "King George Station",
      position: {
        lat: 49.182775004531194, lng: -122.84479582111682
      },
    },
    {
      title: "Surrey Central Station",
      position: {
        lat: 49.18957260265159, lng: -122.84795293287057
      },
    },
    {
      title: "Gateway Station",
      position: {
        lat: 49.19896226474907, lng: -122.85068250472709
      },
    },
    {
      title: "Scott Road Station",
      position: {
        lat: 49.20439518497688, lng: -122.87429409695855
      },
    },
  ],
};

export default skytrain_expo_line;
