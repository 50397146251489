import ISpot from "./ISpot";

import Icon from "../../../../images/markers/marker_healthcare.svg";

const healthCare: ISpot = {
  color: "#0288d1",
  icon: Icon,
  category: "Healthcare",
  map: {
    center: {
      lat: 49.15902426254065, lng: -122.84541755403069
    },
    zoom: 13,
  },
  markers: [
    {
      title: "Surrey Memorial Hospital",
      position: {
        lat: 49.176407843289255,
        lng: -122.84253433393418
      },
    },
    {
      title: "Health & Technology District",
      position: {
        lat: 49.178169245152425,
        lng: -122.8431568085847
      },
    },
    {
      title: "Civic Family Health Centre",
      position: {
        lat: 49.16230500183997,
        lng: -122.84635002184989
      },
    },
    {
      title: "LifeLabs Medical Laboratory Services",
      position: {
        lat: 49.13775699141745,
        lng: -122.84475161263298
      },
    },
  ],
};

export default healthCare;
