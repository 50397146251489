import ISpot from "./ISpot";

import Icon from "../../../../images/markers/marker_future-surrey-langley-skytrain-line.svg";

const future_surrey_langley_skytrain_line: ISpot = {
  color: "#208383",
  icon: Icon,
  category: "Future Surrey-Langley SkyTrain Line",
  map: {
    center: {
      lat: 49.168200312900375, lng: -122.80679469399165
    },
    zoom: 13,
  },
  markers: [
    {
      title: "Proposed Station @ 140 St",
      position: {
        lat: 49.17876149468129, lng: -122.83446333619602
      },
    },
    {
      title: "Proposed Station @ 152 St",
      position: {
        lat: 49.16608676287511, lng: -122.80146627837618
      },
    },
    {
      title: "Proposed Station @ 160 St",
      position: {
        lat: 49.15734937973871, lng: -122.77890172379583
      },
    },
  ],
};

export default future_surrey_langley_skytrain_line;
