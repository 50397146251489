import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import IInitialState from "../interface/IInitialState";
import { RootState } from "./store";
import TAmenities from "../constant/map/TAmenities";

const initialState: IInitialState = {
  amenities: "",
};

const _ = require("lodash");

export const presentationSlice = createSlice({
  name: "presentation",
  initialState,
  reducers: {
    updateAmenities: {
      reducer: (
        state,
        action: PayloadAction<{ amenities: TAmenities | "" }>
      ) => {
        state.amenities = action.payload.amenities;
      },
      prepare: ({ amenities }: { amenities: TAmenities | "" }) => ({
        payload: { amenities },
      }),
    },
  },
  extraReducers: {},
});

export const { updateAmenities } = presentationSlice.actions;

// @ts-ignore
export const getAmenities = (state: RootState) => state.presentation.amenities;
export default presentationSlice.reducer;
