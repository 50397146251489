import ISpot from "./ISpot";

import Icon from "../../../../images/markers/marker_city-services.svg";

const cityServices: ISpot = {
  color: "#757575",
  icon: Icon,
  category: "City Services",
  map: {
    center: {
      lat: 49.17750028864863, lng: -122.84585318583969
    },
    zoom: 13,
  },
  markers: [
    {
      title: "Surrey Civic Plaza",
      position: {
        lat: 49.190452013002165, lng: -122.84831015975149
      },
    },
    {
      title: "Surrey City Hall",
      position: {
        lat: 49.19132210473921, lng: -122.84942447748251
      },
    },
    {
      title: "Gateway Station",
      position: {
        lat: 49.19899828642025, lng: -122.8507134342356
      },
    },
  ],
};

export default cityServices;
