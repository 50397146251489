import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import S from "./style.module.sass";
import GoogleMapApp from "./google-map-app";
import markerStar from "../../images/spera-logo.svg";
import List from "./google-map-app/list";
import { updateAmenities } from "../../redux/presentationSlice";
import classNames from "classnames";

const Map = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(updateAmenities({ amenities: "" }));
    };
  }, []);

  return (
    <div className="row">
      <div className={classNames(S.map, "col-12", "col-lg-8", "g-0")}>
        <div className="ratio ratio-4x3">
          <GoogleMapApp />
        </div>
        <div className={S.float}>
          <img src={markerStar} alt="star" /> <span>SPERA SURREY</span>
        </div>
      </div>

      <div className={classNames("col-12","col-lg-4","g-0", S.list)}>
        <List />
      </div>
    </div>
  );
};

export default Map;
