import ISpot from "./ISpot";

import Icon from "../../../../images/markers/marker_recreation-parks.svg";

const recreationParks: ISpot = {
  color: "#558b2f",
  icon: Icon,
  category: "Recreation / Parks",
  map: {
    center: {
      lat: 49.15868748144753, lng: -122.84078269685283
    },
    zoom: 13,
  },
  markers: [
    {
      title: "Bear Creek Park",
      position: {
        lat: 49.15903370951052,
        lng: -122.84110872000409
      },
    },
    {
      title: "Surrey Arts Centre",
      position: {
        lat: 49.162418178947874,
        lng: -122.8422186232882
      },
    },
    {
      title: "Surrey Libraries – City Centre Branch",
      position: {
        lat: 49.19042191644447,
        lng: -122.84957929787468
      },
    },
    {
      title: "Holland Park",
      position: {
        lat: 49.184125368128925,
        lng: -122.84934119542497
      },
    },
    {
      title: "Dominion Park",
      position: {
        lat: 49.152353527810035,
        lng: -122.85266380584743
      },
    },
    {
      title: "Frank Hurt Park",
      position: {
        lat: 49.140627587084175,
        lng: -122.83984659270726
      },
    },
    {
      title: "Guildford Golf & Country Club",
      position: {
        lat: 49.148311532493736,
        lng: -122.8014996796803
      },
    },
    {
      title: "Surrey Libraries – Newton Branch",
      position: {
        lat: 49.130559297641206,
        lng: -122.83968520096126
      },
    },
    {
      title: "Newton Recreation Centre",
      position: {
        lat: 49.133203887533064,
        lng: -122.84241831827386
      },
    },
    {
      title: "Newton Arena",
      position: {
        lat: 49.13204751667859,
        lng: -122.84163355815637
      },
    },
    {
      title: "Club16 Trevor Linden Fitness",
      position: {
        lat: 49.12769605290496,
        lng: -122.84670362034562
      },
    },
    {
      title: "Anytime Fitness",
      position: {
        lat: 49.13304502846704,
        lng: -122.83905830122423
      },
    },
  ],
};

export default recreationParks;
