import ISpot from "./ISpot";

import Icon from "../../../../images/markers/marker_shopping.svg";

const shopping: ISpot = {
  color: "#f57d03",
  icon: Icon,
  category: "Shopping",
  map: {
    center: {
      lat: 49.162618328874615, lng: -122.84565343030756
    },
    zoom: 13,
  },
  markers: [
    {
      title: "Costco Wholesale",
      position: {
        lat: 49.13837451455093, lng: -122.84737155196846
      },
    },
    {
      title: "King’s Cross Shopping Centre",
      position: {
        lat: 49.1384400212536, lng: -122.84300016600723
      },
    },
    {
      title: "Centre of Newton",
      position: {
        lat: 49.136831159168175, lng: -122.84435847727553
      },
    },
    {
      title: "Little India Plaza",
      position: {
        lat: 49.14875155702639, lng: -122.8677258387126
      },
    },
    {
      title: "Central City",
      position: {
        lat: 49.18661509736235, lng: -122.84901894050147
      },
    },
    {
      title: "Guildford Town Centre",
      position: {
        lat: 49.189636900292726, lng: -122.80245873250405
      },
    },
    {
      title: "Cedar Hills Shopping Centre",
      position: {
        lat: 49.17674001339618, lng: -122.86633190430962
      },
    },
    {
      title: "Payal Business Centre",
      position: {
        lat: 49.1502275741746, lng: -122.8654464539599
      },
    },
    {
      title: "Nordel Crossing Shopping Centre",
      position: {
        lat: 49.161321314726706, lng: -122.88809384443537
      },
    },
    {
      title: "Scott Road Centre",
      position: {
        lat: 49.15019118144659, lng: -122.88934573094188
      },
    },
    {
      title: "Scottsdale Centre",
      position: {
        lat: 49.13191275583587, lng: -122.8913939245487
      },
    },
    {
      title: "Chalo FreshCo",
      position: {
        lat: 49.13284347471571, lng: -122.8401243946118
      },
    },
    {
      title: "Walmart Supercentre",
      position: {
        lat: 49.16602834359518, lng: -122.87794860223936
      },
    },
  ],
};

export default shopping;
