import React from "react";
import classNames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import S from "./style.module.sass";
import data from "../data";
import TAmenities from "../../../../constant/map/TAmenities";
import {
  getAmenities,
  updateAmenities,
} from "../../../../redux/presentationSlice";

const List = () => {
  const dispatch = useDispatch();
  const amenities = useSelector(getAmenities);

  const click = (a: TAmenities) => {
    dispatch(updateAmenities({ amenities: a }));
  };

  const accordionCLasses = (a: TAmenities) => {
    const classes = [
      S.accordion,
      {
        [`${S.active}`]: a === amenities,
      },
    ];
    return classNames(classes);
  };
  const headingCLasses = (a: TAmenities) => {
    const classes = [
      S.heading,
      {
        [`${S.education}`]: a === "Education",
        [`${S.healthcare}`]: a === "Healthcare",
        [`${S.recreation}`]: a === "Recreation / Parks",
        [`${S.shopping}`]: a === "Shopping",
        [`${S.city}`]: a === "City Services",
        [`${S.restaurant}`]: a === "Restaurants & Cafés",
        [`${S.r1_rapid_bus_route}`]: a === "R1 RapidBus Route",
        [`${S.skytrain_expo_line}`]: a === "SkyTrain Expo Line",
        [`${S.future_surrey_langley_skytrain_line}`]: a === "Future Surrey-Langley SkyTrain Line",
      },
    ];
    return classNames(classes);
  };

  const contentCLasses = (a: TAmenities) => {
    const classes = [
      S.content,
      {
        [`${S.education}`]: a === "Education",
        [`${S.healthcare}`]: a === "Healthcare",
        [`${S.recreation}`]: a === "Recreation / Parks",
        [`${S.shopping}`]: a === "Shopping",
        [`${S.city}`]: a === "City Services",
        [`${S.restaurant}`]: a === "Restaurants & Cafés",
        [`${S.r1_rapid_bus_route}`]: a === "R1 RapidBus Route",
        [`${S.skytrain_expo_line}`]: a === "SkyTrain Expo Line",
        [`${S.future_surrey_langley_skytrain_line}`]: a === "Future Surrey-Langley SkyTrain Line",
      },
    ];
    return classNames(classes);
  };
  return (
    <div className={S.list}>
      {data.map((amenities, index) => (
        <div className={accordionCLasses(amenities.category)} key={index}>
          <div
            className={headingCLasses(amenities.category)}
            onClick={() => click(amenities.category)}
          >
            {amenities.category}
          </div>
          <div className={contentCLasses(amenities.category)}>
            <ol>
              {amenities.markers.map((spot, i) => (
                <li key={i}>{spot.title}</li>
              ))}
            </ol>
          </div>
        </div>
      ))}
    </div>
  );
};

export default List;
