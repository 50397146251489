import S from "./Root.module.sass";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import classNames from "classnames";
import React from "react";
import Map from "../map";

const outerTheme = createTheme({
  palette: {
    primary: {
      main: `#114431`,
    },
  },
});
const Root = () => {
  return (
    <ThemeProvider theme={outerTheme}>
      <div className={classNames([S.root, "container"])}>
        <Map />
      </div>
    </ThemeProvider>
  );
};

export default Root;
